/**
 * This file contains most of the actions used around the application
 */
/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */
export function setDefaultInfo(payload) {
    return async function (dispatch, _getState) {
        dispatch({ type: 'SET_USER_TYPE', payload: payload.userType });
        dispatch({ type: 'SET_PALMETTO_SELECTED_GROUP', payload: payload.selectedCounty });
        dispatch({
            type: 'SET_PALMETTO_SELECTED_GROUP_NAME',
            payload: payload.selectedCounty.pvGroupName,
        });
        dispatch({ type: 'PALMETTO_USER_ACCOUNT', payload: payload.palmettoUserAccount });
        dispatch({ type: 'APPLICATION_LOADING_STATE', payload: false });
    };
}
