/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
    'APPLICATION_LOADING_STATE': 'appLoading', // App Loading State
    'SET_LOGIN_STATE': 'loginState', // true if user is logged in
    'SET_APP_TYPE': 'appType', // Sets the App Type
    'SET_USER_SETTINGS': 'userSettings', // true if user is logged in
    'SET_PALMETTO_SELECTED_GROUP': 'selectedGroup', // User Selected Group ID
    'SET_PALMETTO_SELECTED_GROUP_NAME': 'selectedGroupName', // User Selected Group Name
    'SET_HEALTH': 'health', // true if the connection is still active and healthy
    'PALMETTO_USER_ACCOUNT': 'palmettoUserAccount', // basic user information from palmetto
    'ALL_EVENTS': 'allEvents',
    'CURRENT_EVENT': 'currentEvent',
    'ALL_PATIENTS': 'allPatients',
    'CURRENT_PATIENT': 'currentPatient',
    'ALL_VEHICLES': 'allVehicles',
    'CURRENT_VEHICLE': 'currentVehicle',

    'CURRENT_STEP': 'currentStep',
    'CURRENT_TRIP_OBJECT': 'currentTripObject', // This is when a new trip is added

    'ALL_TRIPS': 'allTrips',
    'CURRENT_TRIP': 'currentTrip',

};
