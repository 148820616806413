import React from "react";
import AppShell from "./AppShell";
import store from "./redux/store/store.js";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.Fragment>
        {/* <ScriptsLoader /> */}
        <Provider store={store}>
            <AppShell/>
        </Provider>
    </React.Fragment>
);

