import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from '@zawarski/palmetto-ui-components';

class LoginErrorMsg extends React.Component {
    // constructor(props, context) {
    //     super(props, context);
    // }
    componentDidMount() {
    }

    logout = () => {
        this.props.logout();
    }

    render() {
        const {error, message} = this.props;
        return (
            <div className="layout vertical full-height center vertical-center">
                <div className="app-logo-container">
                    <img className="app-logo" src={'/images/launcher.png'} alt="Palmetto Logo"/>
                </div>
                {
                    error ?
                        <>
                            <span className="title-54-18 login-error-msg">{message}
                            </span>
                            <div className="height-10"></div>
                            <SecondaryButton className="return-to-login" onClick={this.logout}>Return to
                                login</SecondaryButton>
                        </> :
                        <span>Nothing really</span>
                }
            </div>
        );
    }
}

LoginErrorMsg.propTypes = {
    error: PropTypes.bool,
    message: PropTypes.string,
    logout: PropTypes.func
}
export default LoginErrorMsg;
